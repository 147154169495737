<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>From Date</label>
            <b-input
              v-model="filter.date_from"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>To Date</label>
            <b-input
              v-model="filter.date_to"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter by Purpose</label>
            <b-select
              v-model="filter.purpose"
              :options="filterPurposes"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter by Duration</label>
            <b-select
              v-model="filter.duration"
              :options="filterDurations"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mt-2"
        >
          <b-button
            type="button"
            variant="success"
            @click="onShowGenerateForm"
          >
            Generate Voucher
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{
            tableSettings.currentPage * tableSettings.perPage -
              tableSettings.perPage +
              (data.index + 1)
          }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowVoucherModal(row.item)"
            >
              View Vouchers
            </b-button>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-voucher-form"
      scrollable
      no-close-on-backdrop
      title="Generate Voucher"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <ValidationProvider
            #default="{ errors }"
            name="user"
            vid="user"
            rules="required"
          >
            <b-form-group>
              <label for="user">User</label>
              <v-select
                id="user"
                v-model="selectedUser"
                :options="users"
                :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                label="name"
                :disabled="isBusy"
              >
                <template #option="{ email, name }">
                  <strong>{{ name }}</strong><br>
                  <span>{{ email }}</span>
                </template>
                <template #no-options="">
                  No Available User
                </template>
              </v-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="full name"
            vid="full_name"
            rules="required|max:100"
          >
            <b-form-group>
              <label for="full_name">Full Name</label>
              <b-input
                id="full_name"
                v-model="voucher.full_name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="Enter full name"
                autocomplete="off"
                :disabled="isBusy"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="purpose"
            vid="purpose"
            rules="required"
          >
            <b-form-group>
              <label for="purpose">Purpose</label>
              <b-form-select
                id="purpose"
                v-model="voucher.purpose"
                :options="purposes"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="isBusy"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Purpose --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="duration"
            vid="duration"
            rules="required"
          >
            <b-form-group>
              <label for="duration">Duration</label>
              <b-form-select
                id="duration"
                v-model="voucher.duration"
                :options="durations"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="isBusy"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Duration --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="voucher count"
            vid="voucher_count"
            rules="required"
          >
            <b-form-group>
              <label for="voucher_count">Quantity</label>
              <b-form-select
                id="voucher_count"
                v-model="voucher.voucher_count"
                :options="voucher_counts"
                :state="errors.length > 0 ? false : null"
                :disabled="isBusy"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Quantity --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="isBusy"
          @click="ok()"
        >
          Submit
        </b-button>
        <b-button
          variant="danger"
          :disabled="isBusy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-voucher"
      scrollable
      no-close-on-backdrop
      title="View Vouchers"
    >
      <b-card no-body>
        <b-row>
          <b-col
            v-for="code in vouchers"
            :key="code.id"
            cols="12"
          ><strong>{{ code.voucher_code }}</strong>
          </b-col>
        </b-row>
      </b-card>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="isBusy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, SharedListService, AdminWifiVoucherService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminVouchers',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Wifi Vouchers'
    }
  },

  mixins: [formatter],

  data () {
    return {
      isBusy: false,
      purposes: [],
      durations: [],
      vouchers: [],
      users: [],
      selectedUser: null,
      voucher_counts: [
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '3', value: '3' },
        { text: '4', value: '4' },
        { text: '5', value: '5' },
        { text: '6', value: '6' },
        { text: '7', value: '7' },
        { text: '8', value: '8' },
        { text: '9', value: '9' },
        { text: '10', value: '10' }
      ],
      voucher: {
        user: 0,
        full_name: '',
        purpose: null,
        duration: null,
        voucher_count: null
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', label: 'generated at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'user_name', label: 'User' },
          { key: 'full_name', label: 'requestor name' },
          { key: 'purpose_name', label: 'purpose' },
          { key: 'duration_name', label: 'duration' },
          { key: 'voucher_count', label: 'count' }
        ]
      },
      filter: {
        purpose: 'All',
        duration: 'All',
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now())
      }
    }
  },

  computed: {
    filterPurposes () {
      return [].concat({ text: 'All', value: 'All' }, this.purposes)
    },

    filterDurations () {
      return [].concat({ text: 'All', value: 'All' }, this.durations)
    }
  },

  mounted () {
    core.index()
    this.getPurposeList()
    this.getDurationList()
    this.getUserList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AdminWifiVoucherService.get(
            `page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&date_from=${this.filter.date_from}&date_to=${this.filter.date_to}&filter_purpose=${this.filter.purpose}&filter_duration=${this.filter.duration}`
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getPurposeList () {
      await SharedListService.getPurposeList().then(({ data }) => {
        this.purposes = data.map(({ id, purpose_name }) => {
          return { value: id, text: purpose_name }
        })
      })
    },

    async getDurationList () {
      await SharedListService.getDurationList().then(({ data }) => {
        this.durations = data.map(({ id, duration_name }) => {
          return { value: id, text: duration_name }
        })
      })
    },

    async getUserList () {
      await SharedListService.getUserList().then(({ data }) => {
        this.users = data
      })
    },

    onShowGenerateForm () {
      this.selectedUser = null
      this.voucher.full_name = ''
      this.voucher.purpose = null
      this.voucher.duration = null
      this.voucher.voucher_count = null
      this.vouchers = []
      this.$bvModal.show('modal-voucher-form')
    },

    onShowVoucherModal (item) {
      this.vouchers = item.voucher_details
      this.$bvModal.show('modal-view-voucher')
    },

    onFilterChange () {
      this.$refs.table.refresh()
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          const title = 'Do you really want to generate voucher?'
          this.$swal.fire({
            icon: 'question',
            title: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger'
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise((resolve, reject) => {
        this.isBusy = true
        this.voucher.user = this.selectedUser?.id
        AdminWifiVoucherService.post(this.voucher)
          .then(({ data }) => {
            this.$bvModal.hide('modal-voucher-form')
            this.isBusy = false
            this.$swal
              .fire({
                icon: 'success',
                title: 'Success',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              })
              .then(() => {
                this.$refs.table.refresh()
                this.vouchers = data.vouchers
                this.$bvModal.show('modal-view-voucher')
              })
          })
          .catch(error => {
            this.isBusy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                this.$bvModal.msgBoxOk(
                  'Oppss! There were problem with your inputs.',
                  {
                    title: 'Validation Error',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger'
                  }
                )
                resolve(error)
              }
            }
          })
      })
    }
  }
}
</script>
